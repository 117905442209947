<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Buffer Management</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="
                shadow
                overflow-hidden
                border-b border-gray-200
                sm:rounded-lg
              "
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      #Account
                    </th>

                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Amount
                    </th>

                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Replenish At
                    </th>

                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Replenish By
                    </th>

                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="bufferTransactions"
                  class="bg-white divide-y divide-gray-200"
                >
                  <tr v-for="(trans, i) in bufferTransactions.data" :key="i">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <b>{{ trans.account }}</b>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm font-medium text-gray-900">
                        GMD {{ formatPrice(trans.amount) }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {{ trans.created_at | moment("Do MMM, YYYY, h:mm:ss a") }}
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      {{ trans.created_by }}
                    </td>
                  </tr>
                  <!-- More people... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="flex justify-end border-b pb-3">
          <div class="mx-4">
            <h1 class="text-xl font-bold">My Buffer Balance:</h1>
          </div>

          <div v-if="accounts.length > 0">
            <h1 class="text-xl font-bold">
              GMD {{ formatPrice(accounts[2].balance) }}
            </h1>
          </div>
        </div>

        <div class="my-6 mx-11">
          <form class="w-full" @submit.prevent="showConfirmModal()">
            <div class="group">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Select Account
              </label>
              <select
                v-model="form.account"
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
              >
                <option selected disabled value="">
                  Select an account from here
                </option>
                <option
                  v-for="(account, i) in accounts"
                  :key="i"
                  :value="account"
                  :disabled="account.id === 4"
                >
                  {{ account.name }} (Current Balance: {{ account.balance }})
                </option>
              </select>
            </div>

            <div class="group my-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="amount"
              >
                Amount
              </label>
              <input
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                name="name"
                v-model="form.amount"
                type="number"
                :class="{
                  'is-invalid': form.errors.has('amount'),
                }"
                required
                placeholder="Enter amount"
              />
            </div>

            <div
              v-if="accounts[2].balance < form.amount"
              class="group my-4 text-red-400"
            >
              Error
            </div>

            <div class="group">
              <button
                :disabled="accounts[2].balance < form.amount || isLoading"
                :class="{ 'opacity-50 cursor-not-allowed': isLoading }"
                class="
                  px-4
                  py-2
                  rounded
                  text-white
                  inline-block
                  shadow-lg
                  bg-blue-500
                  hover:bg-blue-600
                  focus:bg-blue-700
                  w-full
                  disabled:opacity-50
                "
                @click="showConfirmModal()"
              >
                <span v-if="isLoading">Loading...</span>
                <span v-else>Submit</span>
              </button>
            </div>
          </form>

          <div>
            <confimation
              v-show="isConfirmModalVisible"
              modalHeadline="Please confirm?"
              :isLoading="isLoading"
              :confirmMessage="`Are you sure you want to replenishment GMD${form.amount} to ${form.account.name} `"
              @confirmEvent="confirmRequest"
              @close="closeConfirmModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
// import BranchSales from "../../components/Charts/BranchSales";
import Confimation from "../../components/Confimation.vue";

export default {
  middleware: "auth",
  layout: "default",
  components: {
    Confimation,
  },

  data: () => ({
    bufferTransactions: null,
    accounts: [],
    isLoading: false,
    isConfirmModalVisible: false,

    form: new Form({
      account: "",
      amount: "",
    }),
  }),

  created() {
    var _this = this;
    _this.loadData(1);
    _this.loadBalance();
  },

  methods: {
    total(param) {
      return param.reduce(function (total, item) {
        return total + item.balance_allocated;
      }, 0);
    },

    showConfirmModal() {
      this.isConfirmModalVisible = true;
    },

    closeConfirmModal() {
      this.isConfirmModalVisible = false;
    },

    confirmRequest(req) {
      console.log("User has confirmed", req);
      this.userHasConfirmed = req;

      if (this.userHasConfirmed == "yes") {
        this.onSubmit();
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async onSubmit() {
      // Submit the form
      this.isLoading = true;

      try {
        await this.form.post("/store/buffer_transaction");

        this.isLoading = false;

        this.loadData(1);

        this.form.account = "";
        this.form.amount = "";

        this.closeConfirmModal();
        // manage_floats
        // this.$router.push({ path: "/manage_floats" });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },

    async loadBalance() {
      // console.log("LLLLLLLL");
      try {
        const { data } = await axios.get(`/accounts`);

        this.accounts = data.data;

        return true;
      } catch (e) {
        console.log("Error", e);
        return false;
      }
    },

    async loadData(pageNo, filter) {
      // Submit the form
      if (filter) {
        // filter = filter;
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      //buffer_transactions
      try {
        const { data } = await axios.get(
          `/buffer_transactions?page=${pageNo}&filter=${filter}`
        );

        console.log(data);

        this.bufferTransactions = data;
        this.searching = false;

        // console.log("The current page is: ", this.agents.meta.current_page);

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },
  },
};
</script>